import { Logo } from "components/Logo";
import { Icon } from "components/Social";
import { Link } from "gatsby";
import React from "react";

export function Footer({ className = "" }) {
  const year = new Date().getFullYear().toString();

  return (
    <div className={`site-footer container mx-auto mb-20 ${className}`}>
      <div className="md:flex md:justify-between md:items-end">
        <div>
          <Logo className="block w-32 md:w-32 mx-auto h-auto" />
        </div>
        <div className="my-8 md:my-0">
          <div className="flex gap-6 justify-center social">
            <a
              target="_blank"
              href="https://www.instagram.com/thebhangraclubuk"
            >
              <Icon platform="instagram" />
            </a>
            <a target="_blank" href="https://www.facebook.com/thebhangraclub">
              <Icon platform="facebook" />
            </a>
            {/* <a target="_blank" href="https://twitter.com/">
              <Icon platform="twitter" />
            </a> */}
          </div>
        </div>
      </div>
      <div className="flex items-center flex-col-reverse md:flex-row md:justify-between md:items-end md:mt-4">
        <div>
          <p className="text-xs mt-2 md:mt-0">&copy; THE BHANGRA CLUB {year}</p>
        </div>
        <div>
          <div className="site-footer-links relative text-xs">
            {/* <Link to="/privacy-policy">PRIVACY POLICY</Link>
            <Link to="/terms-and-conditions">TERMS &amp; CONDITIONS</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
