import React, { useEffect, useState } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { useLocation } from "@reach/router";

import { Link } from "gatsby";
import { Logo } from "components/Logo";

const pages = [];

export function Header({ overlay = false }) {
  const [navOpen, setNavOpen] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setNavOpen(false);
  }, [pathname]);

  return (
    <nav
      id="header"
      className={`${
        overlay ? "absolute" : "relative"
      } flex site-header w-full z-30 top-0`}
      style={
        overlay && !navOpen
          ? { background: "transparent", "--on-site-header": "#fff" }
          : null
      }
    >
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-6">
        <div className="flex items-center">
          <Link activeClassName="active" to="/"></Link>
        </div>
      </div>
    </nav>
  );
}
